import React, { useEffect, useState } from "react";
import CountryRibbon from "../components/CountryRibbon";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import CreateAccount from "../components/CreateAccount";
import ConfirmModal from "../components/ConfirmModal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Accounts = ({ domain, userData }) => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");

  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const handleCloseCreateAccountModal = () => {
    setShowCreateAccountModal(false);
  };
  const handleShowCreateAccountModal = () => {
    setShowCreateAccountModal(true);
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };
  const handleShowConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const fetchUsers = () => {
    fetch("/api/users/admins")
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error("Error fetching admins:", error));
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleFormSubmit = () => {
    fetchUsers();
  };

  function formatPermissions(permissionsString) {
    if (!permissionsString) return ""; // If permissions string is null or undefined, return empty string

    // Parse the stringified JSON into an object
    const permissions = JSON.parse(permissionsString);

    // Filter out the true permissions and join them with commas
    const truePermissions = Object.keys(permissions).filter(
      (key) => permissions[key] === true
    );
    return truePermissions.join(", ");
  }

  const handleDeleteUser = (userId) => {
    handleShowConfirmModal();
    setUserId(userId);
  };

  const handleProceed = () => {
    fetch(`/api/users/${userId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          fetchUsers();
        } else {
          console.error("Error deleting user:", response);
        }
      })
      .catch((error) => console.error("Error deleting user:", error));
    handleCloseConfirmModal();
  };

  return (
    <>
      <Helmet>
        <title>Accounts | BLEEP</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <CountryRibbon domain={domain} />
      <div
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
          background:
            "linear-gradient(90deg, rgb(255, 72, 0) 0%, rgb(255, 124, 1) 100%)",
        }}
      >
        <div className="row d-flex align-items-center">
          <div
            className="col"
            style={{ color: "white", padding: "20px" }}
          >
            <Link
              to="/"
              className="text-decoration-none text-white"
            >
              <b>
                <i className="fas fa-arrow-left"></i> Home
              </b>
              <br />
            </Link>
            <b
              style={{
                fontSize: "30px",
                lineHeight: "20px",
                textShadow: "#1c1c1c 1px 2px 0px",
              }}
            >
              Accounts
            </b>
            <p
              style={{ paddingTop: "10px", textShadow: "#1c1c1c 1px 1px 0px" }}
            >
              Here, you can manage all escalated accounts.
            </p>

            <button
              type="button"
              className="btn btn-light btn-lg bleep-btn"
              onClick={handleShowCreateAccountModal}
            >
              <span style={{ marginRight: "10px" }}>
                <i className="fa-solid fa-plus-circle"></i>
              </span>
              <b>Create account</b>
            </button>
          </div>
        </div>
      </div>

      <div
        className="container mt-4"
        style={{ overflowY: "auto" }}
      >
        <table className="table  table-dark">
          <thead>
            <tr>
              <th>type</th>
              <th>display name</th>
              <th>email</th>
              <th>organization</th>
              <th>permissions</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr>
                <td>{user.type}</td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{user.organization}</td>
                <td>{formatPermissions(user.permissions)}</td>
                <td>
                  {user.organization != "Greenbridge" && (
                    <a href="#">
                      <i
                        onClick={() => handleDeleteUser(user._id)}
                        className="fas fa-trash-alt text-danger"
                      ></i>
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CreateAccount
        domain={domain}
        show={showCreateAccountModal}
        handleClose={handleCloseCreateAccountModal}
        handleFormSubmit={handleFormSubmit}
      />
      <ConfirmModal
        show={showConfirmModal}
        handleClose={handleCloseConfirmModal}
        handleProceed={handleProceed}
        confirmMessage="CAUTION: Are you sure you want to delete this user? This cannot be
        undone!"
      />
    </>
  );
};

export default Accounts;
