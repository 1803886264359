import React, { useEffect, useState } from "react";
import CountryRibbon from "../components/CountryRibbon";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Helmet } from "react-helmet-async";

const SignIn = ({ domain, handleSignIn }) => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [authenticationError, setAuthenticationError] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const signIn = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      username: username,
      password: password,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("/api/users/authenticateadmin", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Authentication failed");
        }
        return response.json();
      })
      .then((data) => {
        setAuthenticationError(false);
        Cookies.set("userId", data.user._id, { expires: 1 / 48 });
        Cookies.set("userType", data.user.type, { expires: 1 / 48 });
        Cookies.set("userName", data.user.username, { expires: 1 / 48 });
        Cookies.set("userEmail", data.user.email, { expires: 1 / 48 });
        Cookies.set("userPassword", password, { expires: 1 / 48 });
        Cookies.set("userOrg", data.user.organization, { expires: 1 / 48 });
        Cookies.set("userPermissions", data.user.permissions, {
          expires: 1 / 48,
        });
        Cookies.set("userAnonymous", data.user.anonymous, {
          expires: 1 / 48,
        });
        handleSignIn();
        navigate(`/`);
      })
      .catch((error) => {
        setAuthenticationError(true);
        console.error("error", error);
      });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        signIn();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [username, password]);

  return (
    <>
      <Helmet>
        <title>Sign-In | BLEEP</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <CountryRibbon domain={domain} />
      <div
        style={{
          paddingInline: "10px",
          paddingTop: "50px",
          height: "70vh",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div
          className="col-12 col-md-6 row"
          style={{ backgroundColor: "white", borderRadius: "20px" }}
        >
          <div
            className="col d-none d-lg-flex d-xl-flex align-items-center justify-content-center"
            style={{ paddingBlock: "15px" }}
          >
            <Splide
              options={{
                perPage: 1,
                rewind: true,
                autoplay: true,
                interval: 2000,
                arrows: false,
              }}
            >
              <SplideSlide>
                <div style={{ position: "relative" }}>
                  <img
                    src="/images/slide1.png"
                    style={{ width: "100%", borderRadius: "20px" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      padding: "10px",
                      paddingBottom: "20px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust background color and opacity as needed
                      color: "#fff", // Adjust text color as needed
                      borderRadius: "20px",
                      lineHeight: "20px",
                    }}
                    className="caption"
                  >
                    <b>REPORT</b>
                    <br />
                    <p>Empowering consumers to report counterfeit products.</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div style={{ position: "relative" }}>
                  <img
                    src="/images/slide2.png"
                    style={{ width: "100%", borderRadius: "20px" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      padding: "10px",
                      paddingBottom: "20px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust background color and opacity as needed
                      color: "#fff", // Adjust text color as needed
                      borderRadius: "20px",
                      lineHeight: "20px",
                    }}
                    className="caption"
                  >
                    <b>VERIFY</b>
                    <br />
                    <p>Creating Awareness about Counterfeits</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div style={{ position: "relative" }}>
                  <img
                    src="/images/slide3.png"
                    style={{ width: "100%", borderRadius: "20px" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      textAlign: "center",
                      width: "100%",
                      padding: "10px",
                      paddingBottom: "20px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust background color and opacity as needed
                      color: "#fff", // Adjust text color as needed
                      borderRadius: "20px",
                      lineHeight: "20px",
                    }}
                    className="caption"
                  >
                    <b>TAKE ACTION</b>
                    <br />
                    <p>
                      Increase responsiveness & accountability by brands and
                      enforcement agencies.
                    </p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
          <div
            className="col-12 col-lg-6"
            style={{ padding: "20px", paddingTop: "30px" }}
          >
            <div className="d-flex justify-content-end">
              <Link
                className="text-dark"
                onClick={() => navigate(-1)}
                style={{ marginTop: "-20px", marginRight: "-10px" }}
              >
                <i
                  className="fas fa-close"
                  style={{ fontSize: 30 }}
                ></i>
              </Link>
            </div>

            <h3>
              <b>Sign-in to proceed</b>
            </h3>

            {authenticationError && (
              <div
                className="alert alert-danger"
                role="alert"
              >
                <i className="fas fa-exclamation-circle"></i> Invalid
                credentials
              </div>
            )}

            <form method="post">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="username"
                  placeholder="username"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <label htmlFor="floatingInput">Email address*</label>
              </div>
              <div className="form-floating">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor="password">Password*</label>
              </div>
              <div
                className="form-check mt-3"
                align="left"
              >
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={handleTogglePassword}
                  />
                  Show password
                </label>
              </div>
              <button
                type="button"
                id="submitbtn"
                name="submitbtn"
                className="btn btn-dark btn-lg mt-4 w-100"
                style={{ paddingBlock: "20px" }}
                onClick={signIn}
              >
                Let's go <i className="fas fa-arrow-right"></i>
              </button>
            </form>
          </div>
        </div>
        {/* <div
          style={{ marginTop: "20px" }}
          className="col-lg-3 col-12"
        >
          <div className="mb-2 text-light">
            <span>Need an account for your brand?</span>
          </div>
          <a className="text-decoration-none">
            <button
              className="btn btn-large btn-light"
              style={{ height: "50px", width: "100%" }}
            >
              Join bleep now!
            </button>
          </a>
        </div> */}
      </div>
    </>
  );
};

export default SignIn;
