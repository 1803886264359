import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Post = ({ post, showEmailAuthModal }) => {
  const navigate = useNavigate();

  const userId = Cookies.get("userId");
  const userEmail = Cookies.get("userEmail");

  const formattedDateTime = new Date(post.dateTime).toLocaleString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const postClick = () => {
    navigate(`/post/${post._id}`);
  };

  return (
    <div
      className="col pb-3"
      onClick={() => postClick()}
    >
      <div className="bleep-card card">
        <div
          className="row align-items-center"
          style={styles.row}
        >
          <div className="col-7 flex-row d-flex align-items-center">
            <div
              style={{
                paddingRight: 5,
              }}
            >
              <i className="fas fa-user-circle" />
            </div>
            <div>
              <span
                className="card-title fw-bold"
                style={styles.title}
              >
                {post.displayname}
              </span>
              <br />
              <span style={styles.date}>{formattedDateTime}</span>
            </div>
          </div>
          <div
            className="col-5 "
            align="right"
          >
            {post.status === "Pending action" ? (
              <div className=" status noaction">
                <span className="text-end">{post.status}</span>
              </div>
            ) : post.status === "Ongoing action" ? (
              <div className=" status action">
                <span className="text-end">{post.status}</span>
              </div>
            ) : post.status === "Resolved" ? (
              <div className=" status resolved">
                <span className="text-end">{post.status}</span>
              </div>
            ) : null}
          </div>
        </div>

        <div>
          {post.imageUrls.length > 1 ? (
            <div
              style={{ display: "flex", paddingInline: 10, paddingBottom: 10 }}
            >
              {/* Large image on the left */}
              {post.imageUrls.length > 0 && (
                <div style={{ flex: 1.5 }}>
                  <img
                    src={post.imageUrls[0]}
                    alt="Post"
                    style={{
                      width: "100%",
                      height: "210px",
                      borderRadius: "10px",
                      backgroundColor: "#7f7f7f",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}

              <div
                style={{
                  marginLeft: "10px",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                {/* Right column with stacked images */}
                {post.imageUrls.slice(1, 3).map((item, index) => (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      position: "relative",
                      marginBottom: index === 0 ? "10px" : "0",
                    }}
                  >
                    <img
                      src={item}
                      alt={`Post ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "100px",
                        borderRadius: "10px",
                        backgroundColor: "#7f7f7f",
                        objectFit: "cover",
                      }}
                    />

                    {index === 1 && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                          backgroundColor: "rgba(0, 0, 0, 0.36)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <i
                          className="fas fa-arrow-right"
                          style={{
                            color: "white",
                            fontSize: "20px",
                            marginBottom: "5px",
                          }}
                        ></i>
                        <p
                          style={{
                            color: "white",
                            fontFamily: "Montserrat, sans-serif",
                            fontSize: "12px",
                            lineHeight: "12px",
                            margin: 0,
                          }}
                        >
                          See more
                        </p>
                      </div>
                    )}
                  </div>
                ))}

                {/* View if there is no third image */}
                {post.imageUrls.length < 3 && (
                  <div style={{ width: "100%", position: "relative" }}>
                    <div
                      style={{
                        width: "100%",
                        height: "100px",
                        borderRadius: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.36)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <i
                        className="fas fa-arrow-right"
                        style={{
                          color: "white",
                          fontSize: "20px",
                          marginBottom: "5px",
                        }}
                      ></i>
                      <p
                        style={{
                          color: "white",
                          fontFamily: "Montserrat, sans-serif",
                          fontSize: "12px",
                          lineHeight: "12px",
                          margin: 0,
                        }}
                      >
                        See more
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                paddingInline: 10,
                paddingBottom: 10,
              }}
            >
              <img
                src={post.imageUrls[0]}
                alt="Post"
                style={{
                  width: "100%",
                  height: "210px",
                  borderRadius: "10px",
                  backgroundColor: "#7f7f7f",
                  objectFit: "cover",
                }}
              />
            </div>
          )}
        </div>
        <div className="card-body">
          <div
            className="flex-row d-flex align-items-center justify-content-between"
            style={styles.actions}
          >
            <div>
              <span>
                <i className="fas fa-thumbs-up"></i>&nbsp;
                {post.likes} &nbsp;| &nbsp;
                <i className="fas fa-thumbs-down"></i>&nbsp;
                {post.dislikes}
              </span>
            </div>
            <span>
              <i className="fas fa-comments"></i>&nbsp; {post.comments}
            </span>
          </div>
          <p
            className="card-text"
            style={styles.description}
          >
            {post.description.length > 120 ? (
              <>
                {post.description.slice(0, 120)} ...
                <b>READ MORE</b>
              </>
            ) : (
              post.description
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  row: {
    paddingBottom: "10px",
    color: "white",
    padding: "10px",
    lineHeight: "10px",
  },
  title: {
    fontSize: "0.8em",
  },
  date: {
    fontSize: "0.5em",
  },
  actions: {
    paddingBottom: "20px",
  },
  description: {
    fontSize: "0.8em",
    lineHeight: "1.2em",
    height: "80px",
  },
};

export default Post;
