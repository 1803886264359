import React, { useEffect, useState } from "react";
import CountryRibbon from "../components/CountryRibbon";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet-async";

const Contact = ({ domain }) => {
  return (
    <>
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="Bleep is a multimedia application designed to empower consumers to report suspected counterfeit or substandard products."
        />
      </Helmet>

      <div
        style={{
          paddingLeft: "20px",
          paddingTop: "40px",
          paddingBottom: "20px",
          background:
            "linear-gradient(90deg, rgb(255, 72, 0) 0%, rgb(255, 124, 1) 100%)",
        }}
      >
        <div className="row d-flex align-items-center">
          <div
            className="col d-flex align-items-center"
            style={{ color: "white", padding: "20px", flexDirection: "row" }}
          >
            <Link
              to="/"
              className="text-decoration-none text-white"
            >
              <span
                style={{
                  backgroundColor: "#00000089",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  marginRight: "10px",
                }}
              >
                <i className="fas fa-arrow-left"></i>
              </span>
            </Link>

            <div>
              <b
                style={{
                  fontSize: "30px",
                  textShadow: "#1c1c1c 1px 2px 0px",
                }}
              >
                Contact Us
              </b>
              <p>
                Require any assistance? Do you have any questions/suggestions?
                Please free to contact us!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          paddingInline: "20px",
          paddingBlock: "40px",
        }}
      >
        <div
          className="col-12 col-lg-6"
          style={{
            paddingBlock: "20px",
            paddingInline: "20px",
            backgroundColor: "#ffffff",
            borderRadius: "10px",
          }}
        >
          {/* Contact form goes here
          Your name
          Your email
          Your phone number (optional)
          Your message
          Send
          */}
          <form>
            <div className="form-group">
              <label htmlFor="name">Your name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                aria-describedby="nameHelp"
                placeholder="Enter your name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Your email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                aria-describedby="emailHelp"
                placeholder="Enter your email"
              />
              <small
                id="emailHelp"
                className="form-text text-muted"
              >
                We'll never share your email with anyone else.
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="phone">Your phone number</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                aria-describedby="phoneHelp"
                placeholder="Enter your phone number"
              />
              <small
                id="phoneHelp"
                className="form-text text-muted"
              >
                We'll never share your phone number with anyone else.
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="message">Your message</label>
              <textarea
                className="form-control"
                id="message"
                rows="3"
                placeholder="Enter your message"
              ></textarea>
            </div>
            <br />
            <button
              type="submit"
              className="btn btn-dark btn-lg"
            >
              Send Message <i className="fas fa-arrow-right"></i>
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
