import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UG, KE } from "country-flag-icons/react/3x2";
import lottie from "lottie-web";
import BleepAnimationDark from "../components/lottie/BleepAnimationDark.json";

const Navbar = ({ domain, userData, signOut }) => {
  const userId = userData[0];
  const userType = userData[1];
  const userName = userData[2];
  const userEmail = userData[3];
  const userPassword = userData[4];
  const userOrg = userData[5];

  const [isHovered, setIsHovered] = useState(false);
  const bleepanimation = React.useRef(null);
  useEffect(() => {
    if (bleepanimation.current && !bleepanimation.current.animationLoaded) {
      bleepanimation.current.animationLoaded = true; // Mark animation as loaded to prevent re-render
      lottie.loadAnimation({
        container: bleepanimation.current,
        renderer: "canvas",
        loop: true,
        autoplay: true,
        animationData: BleepAnimationDark,
      });
    }
  }, []);

  return (
    <header className="sticky-top bleep-navbar">
      <div
        className="d-flex align-items-center"
        style={{ paddingBlock: "15px", paddingInline: "30px" }}
      >
        <div className="row w-100">
          <div className="col d-none d-md-flex justify-content-start align-items-center">
            <div className="d-md-flex justify-content-end">
              <Link
                to="/about"
                className="text-decoration-none d-flex align-items-center"
              >
                <button
                  className="btn"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 1)",
                    color: "#ffffff",
                    marginRight: "10px",
                  }}
                >
                  About Bleep
                </button>
              </Link>
            </div>

            <div className="d-md-flex justify-content-end">
              <Link
                to="/faqs"
                className="text-decoration-none d-flex align-items-center"
              >
                <button
                  className="btn"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 1)",
                    color: "#ffffff",
                    marginRight: "10px",
                  }}
                >
                  FAQs
                </button>
              </Link>
            </div>
          </div>
          <div className="col-2">
            <Link
              to="/"
              className="text-decoration-none d-flex align-items-center"
            >
              <div
                style={{ position: "relative", display: "inline-block" }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                {/* Image */}
                <img
                  src="/images/logo_dark.png"
                  className={`hover-image ${
                    isHovered ? "fade-out" : "fade-in"
                  }`}
                  style={{
                    width: "150px",
                    transition: "opacity 0.5s ease", // Smooth transition effect
                    opacity: isHovered ? 0 : 1, // Fade out on hover
                  }}
                />

                {/* Animated div */}
                <div
                  ref={bleepanimation}
                  className={`hover-div ${isHovered ? "fade-in" : "fade-out"}`}
                  style={{
                    position: "absolute",
                    top: -45,
                    width: 150,
                    height: 150,
                    transition: "opacity 0.5s ease", // Smooth transition effect
                    opacity: isHovered ? 1 : 0, // Fade in on hover
                  }}
                ></div>
              </div>
            </Link>
          </div>
          <div className="col d-flex justify-content-end align-items-center">
            <div className="d-none d-md-flex justify-content-end">
              <Link
                to="/contact"
                className="text-decoration-none d-flex align-items-center"
              >
                <button
                  className="btn"
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 1)",
                    color: "#ffffff",
                    marginRight: "10px",
                  }}
                >
                  Contact Us
                </button>
              </Link>
            </div>

            {!userId ? (
              <div className="d-md-flex justify-content-end">
                <Link
                  to="/signin"
                  className="text-decoration-none d-flex align-items-center"
                >
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "#000000",
                    }}
                  >
                    Sign-in <i className="fas fa-sign-in"></i>
                  </button>
                </Link>
              </div>
            ) : (
              <div className="d-md-flex justify-content-end">
                <Link onClick={signOut}>
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      color: "#000000",
                    }}
                  >
                    Sign-out <i className="fas fa-sign-out"></i>
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
