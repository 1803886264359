import React, { useEffect, useState } from "react";
import CountryRibbon from "../components/CountryRibbon";
import Post from "../components/Post";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import ChangeEmailModal from "../components/ChangeEmailModal";
import ChangeDisplayNameModal from "../components/ChangeDisplayNameModal";
import { Helmet } from "react-helmet-async";

const MyAccount = ({ domain, userData, refreshUserData }) => {
  const userId = userData[0];
  const userType = userData[1];
  const userName = userData[2];
  const userEmail = userData[3];
  const userPassword = userData[4];
  const userOrg = userData[5];
  const userPermissions = userData[6];
  const userAnonymous = userData[7];

  const [userDisplayName, setUserDisplayName] = useState(undefined);
  useEffect(() => {
    if (userAnonymous == "true" && userId !== undefined) {
      setUserDisplayName("Bleeper#" + userId.slice(-6));
    } else {
      setUserDisplayName(userName);
    }
  }, [userName, userId, refreshUserData]);

  const [showChangeEmailModalModal, setShowChangeEmailModalModal] =
    useState(false);
  const handleCloseChangeEmailModalModal = () => {
    setShowChangeEmailModalModal(false);
  };
  const handleShowChangeEmailModalModal = () =>
    setShowChangeEmailModalModal(true);

  const [showChangeDisplayNameModal, setShowChangeDisplayNameModal] =
    useState(false);
  const handleCloseChangeDisplayNameModal = () => {
    setShowChangeDisplayNameModal(false);
  };
  const handleShowChangeDisplayNameModal = () => {
    setShowChangeDisplayNameModal(true);
  };

  const saveDisplayName = () => {
    refreshUserData();
    handleCloseChangeDisplayNameModal();
  };

  return (
    <>
      <Helmet>
        <title>My Account | BLEEP</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <CountryRibbon domain={domain} />
      <div
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
          background:
            "linear-gradient(90deg, rgb(255, 72, 0) 0%, rgb(255, 124, 1) 100%)",
        }}
      >
        <div className="row d-flex align-items-center">
          <div
            className="col"
            style={{ color: "white", padding: "20px" }}
          >
            <Link
              to="/"
              className="text-decoration-none text-white"
            >
              <b>
                <i className="fas fa-arrow-left"></i> Home
              </b>
              <br />
            </Link>
            <b
              style={{
                fontSize: "30px",
                lineHeight: "20px",
                textShadow: "#1c1c1c 1px 2px 0px",
              }}
            >
              My Account
            </b>
            <p
              style={{ paddingTop: "10px", textShadow: "#1c1c1c 1px 1px 0px" }}
            >
              Here, you can manage your account preferences.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{ padding: 20 }}
        className="row row-cols-lg-3 row-cols-sm-1 row-cols-1"
      >
        <div
          className="col"
          onClick={handleShowChangeEmailModalModal}
        >
          <div className="bleep-settings-card card">
            <p className="bleep-card-title">
              Email Address <i className="fas fa-edit"></i>
            </p>
            <p className="bleep-card-text">{userEmail}</p>
          </div>
        </div>
        <div
          className="col"
          onClick={handleShowChangeDisplayNameModal}
        >
          <div className="bleep-settings-card card">
            <p className="bleep-card-title">
              Display Name <i className="fas fa-edit"></i>
            </p>
            <p className="bleep-card-text">{userDisplayName}</p>
          </div>
        </div>
      </div>
      <ChangeEmailModal
        domain={domain}
        show={showChangeEmailModalModal}
        handleClose={handleCloseChangeEmailModalModal}
        currentEmail={userEmail}
        changeEmail={true}
      />
      <ChangeDisplayNameModal
        show={showChangeDisplayNameModal}
        handleClose={handleCloseChangeDisplayNameModal}
        userData={userData}
        saveDisplayName={saveDisplayName}
      />
    </>
  );
};

export default MyAccount;
