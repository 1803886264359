import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { useNavigate } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import Badge from "react-bootstrap/Badge";
import EmailAuth from "../components/EmailAuth";
import ConfirmModal from "../components/ConfirmModal";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import SuggestedBleep from "../components/SuggestedBleep";
import { Helmet } from "react-helmet-async";

const Post = ({ domain, refreshUserData }) => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [showCommentPostedToast, setShowCommentPostedToast] = useState(false);
  const [showCommentDeletedToast, setShowCommentDeletedToast] = useState(false);
  const [showDeletePost, setShowDeletePost] = useState(false);
  const [showMarkResolved, setShowMarkResolved] = useState(false);

  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [userOrg, setUserOrg] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);

  const getUserData = () => {
    setUserDataLoaded(false);
    // Retrieve user data from cookies
    const userIdFromCookie = Cookies.get("userId");
    const userTypeFromCookie = Cookies.get("userType");
    const userNameFromCookie = Cookies.get("userName");
    const userEmailFromCookie = Cookies.get("userEmail");
    const userPasswordFromCookie = Cookies.get("userPassword");
    const userOrgFromCookie = Cookies.get("userOrg");
    const userPermissionsFromCookie = Cookies.get("userPermissions");

    // Update state with user data
    setUserId(userIdFromCookie);
    setUserType(userTypeFromCookie);
    setUserName(userNameFromCookie);
    setUserEmail(userEmailFromCookie);
    setUserPassword(userPasswordFromCookie);
    setUserOrg(userOrgFromCookie);
    setUserPermissions(userPermissionsFromCookie);
    setUserDataLoaded(true);
  };

  const [showEmailAuthModal, setShowEmailAuthModal] = useState(false);
  const handleCloseEmailAuthModal = () => {
    setShowEmailAuthModal(false);
  };
  const handleShowEmailAuthModal = () => setShowEmailAuthModal(true);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };
  const handleShowConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const handleAuthComplete = () => {
    handleCloseEmailAuthModal();
    refreshUserData();
    getUserData();
  };

  // Mark post as resolved
  const handleProceed = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      user: userId,
      post: post._id,
    });
    fetch(`/api/comments/resolved`, {
      method: "POST",
      headers: myHeaders,
      body: raw,
    })
      .then((response) => response.json())
      .then((data) => {
        handleCloseConfirmModal();
        fetchPost();
        fetchComments();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  Fancybox.bind("[data-fancybox]", {});

  function convertDateTimeFormat(dateTimeString) {
    const dateTime = new Date(dateTimeString).toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return dateTime;
  }

  const [posts, setPosts] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const fetchPosts = () => {
    let url = "/api/posts";
    if (userId) {
      url += `?user=${userId}`;
    }

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPosts(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, [userId]);

  const fetchPost = () => {
    let url = `/api/posts/${id}`;

    if (userId) {
      url += `?user=${userId}`;
    }

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPost(data);
        checkMarkResolved(data);
        checkDeletePost(data);
      });
  };

  const checkMarkResolved = (postData) => {
    if (
      postData &&
      postData.status === "Ongoing action" &&
      ((userPermissions && userPermissions.includes("resolvePosts")) ||
        userType === "superadmin")
    ) {
      setShowMarkResolved(true);
    } else {
      setShowMarkResolved(false);
    }
  };

  const checkDeletePost = (postData) => {
    const timeDifferenceInMinutes = () =>
      Math.floor((new Date() - new Date(postData.dateTime)) / (1000 * 60));

    let checkInterval;
    const checkTimeDifference = () => {
      const difference = timeDifferenceInMinutes();
      if (difference <= 5) {
        setShowDeletePost(true);
      } else {
        setShowDeletePost(false);
        clearInterval(checkInterval);
      }
    };

    if (
      (userPermissions && userPermissions.includes("deletePosts")) ||
      userType === "superadmin"
    ) {
      setShowDeletePost(true);
    } else if (userId !== undefined && postData.user === userId) {
      checkTimeDifference();
      const checkInterval = setInterval(checkTimeDifference, 60000); // Check every minute
    } else {
      setShowDeletePost(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      getUserData();
      if (userDataLoaded) {
        fetchPost();
        fetchComments();

        // Scroll to top
        window.scrollTo(0, 0);
      }
    };
    fetchData();
  }, [id, userId]);

  const deletePost = () => {
    fetch(`/api/posts/${id}`, {
      method: "DELETE",
    })
      .then((res) => {
        if (res.ok) {
          // Post deleted successfully, redirect to the home page
          navigate(`/`);
        } else {
          // Handle error response
          console.error("Error deleting post:", res.statusText);
        }
      })
      .catch((error) => {
        // Handle fetch error
        console.error("Fetch error:", error);
      });
  };

  const vote = (direction) => {
    if (!userId || !userEmail) {
      handleShowEmailAuthModal();
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        user: userId,
        post: post._id,
        vote: direction,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("/api/votes/post", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          fetchPost();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleWatch = () => {
    if (!userId || !userEmail) {
      handleShowEmailAuthModal();
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        user: userId,
        post: post._id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("/api/watches", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          fetchPost();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const [comments, setComments] = useState([]);
  const [commentsCount, setCommentsCount] = useState(0);
  const fetchComments = () => {
    fetch(`/api/comments/post/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setComments(data);
        setCommentsCount(data.length);
      })
      .catch((error) => console.error("Error fetching comments:", error));
  };

  const handlePostComment = () => {
    fetchComments();
    fetchPost();
    setShowCommentPostedToast(true);
    document.getElementById("bleepThread").scrollTop = 0;
  };

  const postComment = (event) => {
    if (!userId) {
      event.preventDefault();
      handleShowEmailAuthModal();
      return;
    }

    event.preventDefault();

    const formData = new FormData();

    // Append other form data fields
    formData.append("user", userId);
    formData.append("post", post._id);
    formData.append("comment", event.target.comment.value);

    fetch("/api/comments", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        event.target.comment.value = "";
        handlePostComment();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const deleteComment = (commentId) => {
    fetch(`/api/comments/${commentId}`, {
      method: "DELETE",
    })
      .then((res) => {
        if (res.ok) {
          fetchComments();
          setShowCommentDeletedToast(true);
        } else {
          // Handle error response
          console.error("Error deleting comment:", res.statusText);
        }
      })
      .catch((error) => {
        // Handle fetch error
        console.error("Fetch error:", error);
      });
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>{post.displayname} posted a Bleep</title>
        <meta
          name="description"
          content={post.description}
        />
        <meta
          property="og:title"
          content={post.displayname + " posted a Bleep"}
        />
        <meta
          property="og:description"
          content={post.description}
        />
        <meta
          property="og:image"
          content={post.imageUrls[0]}
        />
        <meta
          property="og:url"
          content={window.location.href}
        />
      </Helmet>

      <div
        className="col-12"
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <div
          className="col-12 col-lg-8"
          style={{
            padding: "20px",
            borderRadius: "20px",
            backgroundColor: "#1d1d1d",
            height: "100%",
          }}
        >
          <div
            style={{
              alignSelf: "flex-start",
              textAlign: "left",
              paddingBottom: "20px",
            }}
          >
            <Link
              to="/"
              className="text-decoration-none text-white"
            >
              <b>
                <i className="fas fa-arrow-left"></i> Back
              </b>
              <br />
            </Link>
          </div>
          <div className="d-flex align-items-center">
            {showDeletePost && (
              // More options dropdown
              <div
                className="dropend"
                style={{ marginRight: 10 }}
              >
                <a
                  className="btn btn-dark"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    borderRadius: "100px",
                    backgroundColor: "black",
                  }}
                >
                  <i className="fas fa-ellipsis-v"></i>
                </a>

                {/* Delete post option */}
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item text-danger"
                      href="#"
                      onClick={deletePost}
                    >
                      <i className="fas fa-trash-alt"></i> Delete
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="dropdown-item"
                      href="#"
                    >
                      <i className="fas fa-flag"></i> Report
                    </a>
                  </li> */}
                </ul>
              </div>
            )}

            {/* Show post status */}
            <div style={{ marginRight: "10px" }}>
              {post.status === "Pending action" ? (
                <span className="text-end status noaction">{post.status}</span>
              ) : post.status === "Ongoing action" ? (
                <span className="text-end status action">{post.status}</span>
              ) : post.status === "Resolved" ? (
                <span className="text-end status resolved">{post.status}</span>
              ) : null}
            </div>
          </div>

          <div>
            <div
              className="fw-bold"
              style={{
                color: "white",
                fontSize: "30px",
                marginBottom: "-10px",
              }}
            >
              {post.displayname}
            </div>
            <span style={{ fontSize: "15px", color: "white" }}>
              {convertDateTimeFormat(post.dateTime)}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "20px",
              overflowX: "auto",
              width: "100%",
            }}
          >
            {post.imageUrls.map((imageUrl, index) => (
              <div
                key={index}
                style={{
                  position: "relative", // Ensure this container is the relative parent
                  display: "inline-block", // To make sure the div wraps around the image
                  paddingRight: "10px",
                }}
              >
                <img
                  className="bleep-image"
                  data-fancybox="bleep-gallery"
                  src={imageUrl}
                />

                <div
                  style={{
                    position: "absolute",
                    right: 20,
                    top: 10,
                    paddingBlock: "5px",
                    paddingInline: "20px",
                    backgroundColor: "rgba(255, 255, 255, 0.699)",
                    color: "#000000",
                    borderRadius: "20px",
                    pointerEvents: "none",
                    display: "flex", // To align text and icon
                    alignItems: "center",
                  }}
                >
                  <i className="fas fa-expand-alt"></i>
                </div>
              </div>
            ))}
          </div>

          <div
            style={{
              paddingTop: "20px",
              color: "white",
              fontSize: "15px",
              lineHeight: "1.2em",
            }}
          >
            <b>Description</b>
            <p>{post.description}</p>
          </div>

          <div style={{ width: "100%" }}>
            <div
              style={{
                overflowX: "auto",
                whiteSpace: "nowrap",
                paddingBottom: "10px",
                display: "flex",
              }}
            >
              <div
                style={{
                  backgroundColor: "rgba(34, 34, 34, 1)",
                  borderRadius: "20px",
                  minHeight: "80px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingBlock: "5px",
                  paddingInline: "20px",
                  border: "2px solid #393939",
                  marginRight: "10px",
                }}
              >
                <b style={{ color: "white", fontSize: 12 }}>Purchase address</b>
                <p style={{ color: "white", fontSize: 10 }}>{post.address}</p>
              </div>

              <div
                style={{
                  backgroundColor: "rgba(34, 34, 34, 1)",
                  borderRadius: "20px",
                  minHeight: "80px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingBlock: "5px",
                  paddingInline: "20px",
                  border: "2px solid #393939",
                  marginRight: "10px",
                }}
              >
                <b style={{ color: "white", fontSize: 12 }}>City/Town</b>
                <p style={{ color: "white", fontSize: 10 }}>
                  {post.city ? post.city : "-"}
                </p>
              </div>

              <div
                style={{
                  backgroundColor: "rgba(34, 34, 34, 1)",
                  borderRadius: "20px",
                  minHeight: "80px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingBlock: "5px",
                  paddingInline: "20px",
                  border: "2px solid #393939",
                  marginRight: "10px",
                }}
              >
                <b style={{ color: "white", fontSize: 12 }}>Price</b>
                <p style={{ color: "white", fontSize: 10 }}>
                  {post.price ? post.price + " UGX" : "? UGX"}
                </p>
              </div>
              <div
                style={{
                  backgroundColor: "rgba(34, 34, 34, 1)",
                  borderRadius: "20px",
                  minHeight: "80px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingBlock: "5px",
                  paddingInline: "20px",
                  border: "2px solid #393939",
                  marginRight: "10px",
                }}
              >
                <b style={{ color: "white", fontSize: 12 }}>Category</b>
                <p style={{ color: "white", fontSize: 10 }}>{post.category}</p>
              </div>
            </div>

            <div
              className="d-flex align-items-center"
              style={{
                paddingBlock: "10px",
              }}
            >
              <div
                className="btn-group"
                role="group"
              >
                <button
                  type="button"
                  className={
                    post.uservote == 1
                      ? "btn btn-light btn-sm"
                      : "btn btn-outline-light btn-sm"
                  }
                  style={{
                    borderTopLeftRadius: "50px",
                    borderBottomLeftRadius: "50px",
                  }}
                  onClick={() => vote("up")}
                >
                  <i className="fas fa-thumbs-up"></i>&nbsp;
                  <span className="d-none d-xl-inline">Like: </span>{" "}
                  {post.likes}
                </button>
                <button
                  type="button"
                  className={
                    post.uservote == -1
                      ? "btn btn-light btn-sm"
                      : "btn btn-outline-light btn-sm"
                  }
                  style={{
                    borderTopRightRadius: "50px",
                    borderBottomRightRadius: "50px",
                  }}
                  onClick={() => vote("down")}
                >
                  <i className="fas fa-thumbs-down"></i>
                  <span className="d-none d-xl-inline"></span> {post.dislikes}
                </button>
              </div>

              <button
                type="button"
                className={
                  post.watching == 1
                    ? "btn btn-light btn-sm"
                    : "btn btn-outline-light btn-sm"
                }
                style={{
                  borderRadius: "50px",
                  marginLeft: "10px",
                }}
                onClick={handleWatch}
              >
                <i className="fas fa-eye"></i>&nbsp;
                <span>Watch: {post.watchers}</span>
              </button>

              {showMarkResolved && (
                <button
                  type="button"
                  className="btn btn-outline-success btn-sm"
                  style={{
                    marginLeft: "10px",
                    borderRadius: "50px",
                  }}
                  onClick={handleShowConfirmModal}
                >
                  <i className="fas fa-check-circle"></i>&nbsp;
                  <span>Mark as Resolved</span>
                </button>
              )}
            </div>

            {/* Comment section */}
            <div
              style={{
                paddingBlock: "10px",
              }}
            >
              <span
                className="text-white"
                style={{
                  fontSize: "15px",
                }}
              >
                <i className="fas fa-comments"></i>&nbsp; {commentsCount}{" "}
                Comments
              </span>
            </div>

            <div
              style={{
                paddingBottom: "20px",
              }}
            >
              <form
                onSubmit={postComment}
                encType="multipart/form-data"
              >
                <div className="row align-items-center">
                  <style>
                    {`.form-control::-webkit-input-placeholder {
              color: rgba(255, 255, 255, 0.447);
            }`}
                  </style>

                  {post.status !== "Resolved" ? (
                    <div className=" align-items-center">
                      <div className="row d-flex align-items-center">
                        <div className="col-md-10 col-12 mb-2 mb-lg-0">
                          <textarea
                            className="form-control"
                            id="comment"
                            name="comment"
                            placeholder="Add a comment..."
                            style={{
                              maxHeight: "200px",
                              minHeight: "45px",
                              backgroundColor: "#1d1d1d",
                              border: "1px solid #393939",
                              color: "white",
                              borderRadius: "20px",
                              paddingBlock: "10px",
                              paddingInline: "20px",
                            }}
                            rows="1"
                          ></textarea>
                        </div>
                        <div className="col-md-2 col-12">
                          <button
                            type="submit"
                            className="btn btn-dark w-100"
                            style={{
                              backgroundColor: "#ffffff",
                              color: "rgb(0, 0, 0)",
                              borderRadius: "100px",
                              fontSize: "15px",
                            }}
                          >
                            <i className="fas fa-paper-plane"></i> Comment
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        className="col"
                        style={{
                          color: "#6a6a6a",
                          borderRadius: "20px",
                          paddingBlock: "10px",
                          width: "100%",
                        }}
                      >
                        Comments are now closed for this bleep.
                      </div>
                    </>
                  )}
                </div>
              </form>
            </div>

            {comments?.length > 0 ? (
              <>
                {comments.map((comment) => {
                  // Calculate the time difference in minutes
                  const timeDifferenceInMinutes = Math.floor(
                    (new Date() - new Date(comment.dateTime)) / (1000 * 60)
                  );

                  return (
                    <div
                      key={comment._id}
                      className={`bd-callout ${
                        comment.user && comment.user.type !== "user"
                          ? "bd-callout-right"
                          : "bd-callout-left"
                      }`}
                    >
                      {comment.user && comment.user.type !== "user" ? (
                        <>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "#bbbbbb",
                              lineHeight: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <span>
                                <i className="fas fa-thumbtack"></i> Pinned
                              </span>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                }}
                              >
                                <span>
                                  {comment.user.organization}
                                  <i className="fas fa-check"></i>{" "}
                                </span>
                                <span>@{comment.user.username}</span>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "#bbbbbb",
                            lineHeight: "10px",
                          }}
                        >
                          Bleeper#
                          {comment.user &&
                          comment.user._id &&
                          comment.user._id.length > 6
                            ? comment.user._id.slice(-6)
                            : comment.user
                            ? comment.user._id
                            : ""}
                        </div>
                      )}
                      <span style={{ fontSize: "12px", color: "#7a7a7a" }}>
                        {convertDateTimeFormat(comment.dateTime)}
                      </span>
                      <p>{comment.comment}</p>
                      <div>
                        {comment.status !== "deleted" &&
                          comment.user &&
                          userId === comment.user._id &&
                          timeDifferenceInMinutes <= 5 && (
                            <a
                              className="text-danger text-decoration-none"
                              href="#"
                              onClick={deleteComment.bind(this, comment._id)}
                            >
                              <i className="fas fa-trash-alt"></i> Delete
                            </a>
                          )}
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              // No comments yet
              <div
                style={{
                  color: "#787878",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
                className="empty"
              >
                <p>No comments yet. You can be the first to comment!</p>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            position: "fixed",
            bottom: "20px",
            left: "20px",
            zIndex: 9999,
          }}
        >
          <Toast
            show={showCommentPostedToast}
            onClose={() => setShowCommentPostedToast(false)}
            delay={3000}
            autohide
          >
            <Toast.Body>Your comment has been posted!</Toast.Body>
          </Toast>

          <Toast
            show={showCommentDeletedToast}
            onClose={() => setShowCommentDeletedToast(false)}
            delay={3000}
            autohide
          >
            <Toast.Body>Comment deleted.</Toast.Body>
          </Toast>
        </div>

        <div
          className="d-none d-lg-block col-lg-3"
          style={{
            padding: "20px",
            marginLeft: "20px",
            borderRadius: "20px",
            // backgroundColor: "#1d1d1d",
          }}
        >
          {/* Latest Bleeps */}
          <div
            style={{
              alignSelf: "flex-start",
              textAlign: "left",
              paddingBottom: "20px",
            }}
          >
            <span className="text-white">
              <b>Latest Bleeps</b>
            </span>
          </div>

          {posts &&
            !isLoading &&
            posts.slice(0, 5).map((suggestedbleep) => (
              <SuggestedBleep
                key={suggestedbleep._id}
                post={suggestedbleep}
                showEmailAuthModal={handleShowEmailAuthModal}
              />
            ))}
        </div>
      </div>

      <EmailAuth
        domain={domain}
        show={showEmailAuthModal}
        handleClose={handleCloseEmailAuthModal}
        handleAuthComplete={handleAuthComplete}
      />
      <ConfirmModal
        show={showConfirmModal}
        handleClose={handleCloseConfirmModal}
        handleProceed={handleProceed}
        confirmMessage="Are you sure you want to mark this bleep as resolved? This cannot be undone."
      />
    </>
  );
};

export default Post;
