import React, { useEffect, useState } from "react";
import CountryRibbon from "../components/CountryRibbon";
import PostsPerDayChart from "../components/PostsPerDayChart";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Analytics = ({ domain, userData }) => {
  const navigate = useNavigate();
  const [analytics, setAnalytics] = useState([]);
  const [postsPerDay, setPostsPerDay] = useState([]);

  const fetchAnalytics = () => {
    fetch("/api/posts/analytics")
      .then((response) => response.json())
      .then((data) => setAnalytics(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  const fetchPostsPerDay = () => {
    fetch("/api/posts/postsperday")
      .then((response) => response.json())
      .then((data) => setPostsPerDay(data))
      .catch((error) => console.error("Error fetching data:", error));
  };

  useEffect(() => {
    fetchAnalytics();
    fetchPostsPerDay();
  }, []);

  return (
    <>
      <Helmet>
        <title>Analytics | BLEEP</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <CountryRibbon domain={domain} />
      <div
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
          background:
            "linear-gradient(90deg, rgb(255, 72, 0) 0%, rgb(255, 124, 1) 100%)",
        }}
      >
        <div className="row d-flex align-items-center">
          <div
            className="col"
            style={{ color: "white", padding: "20px" }}
          >
            <Link
              to="/"
              className="text-decoration-none text-white"
            >
              <b>
                <i className="fas fa-arrow-left"></i> Home
              </b>
              <br />
            </Link>
            <b
              style={{
                fontSize: "30px",
                lineHeight: "20px",
                textShadow: "#1c1c1c 1px 2px 0px",
              }}
            >
              Analytics
            </b>
            <p
              style={{ paddingTop: "10px", textShadow: "#1c1c1c 1px 1px 0px" }}
            >
              Here, you can visualize Bleep's data.
            </p>
          </div>
        </div>
      </div>

      <PostsPerDayChart data={postsPerDay} />

      <div
        className="container mt-4"
        style={{ overflowY: "auto", marginBottom: "50px" }}
      >
        <table className="table table-dark">
          <thead>
            <tr>
              <th>category</th>
              <th>reports</th>
              <th>pending action</th>
              <th>ongoing action</th>
              <th>resolved</th>
            </tr>
          </thead>
          <tbody>
            {analytics.map((analytic) => (
              <tr>
                <td>{analytic.category}</td>
                <td>{analytic.counts.totalCount}</td>
                <td>{analytic.counts.pendingactionCount}</td>
                <td>{analytic.counts.ongoingactionCount}</td>
                <td>{analytic.counts.resolvedCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Analytics;
