import React from "react";

const CountryRibbon = ({ domain }) => {
  return (
    <>
      {domain === "ke" ? (
        <section
          style={{
            height: "5px",
            width: "100%",
            background:
              "linear-gradient(90deg, rgba(32,32,32,1) 0%, rgba(255,0,0,1) 42%, rgba(15, 127, 0, 1) 100%)",
          }}
        ></section>
      ) : (
        <section
          style={{
            height: "5px",
            width: "100%",
            background:
              "linear-gradient(90deg, rgba(32,32,32,1) 0%, rgba(255,179,0,1) 42%, rgba(255,0,0,1) 100%)",
          }}
        ></section>
      )}
    </>
  );
};
export default CountryRibbon;
