import React, { useEffect, useState } from "react";

import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import MyBleeps from "./pages/MyBleeps";
import MyAccount from "./pages/MyAccount";
import Post from "./pages/Post";
import SignIn from "./pages/SignIn";
import Accounts from "./pages/Accounts";
import Analytics from "./pages/Analytics";
import Downloads from "./pages/Downloads";
import Verify from "./pages/Verify";
import TermsOfService from "./pages/TermsOfService";
import About from "./pages/About";
import Contact from "./pages/Contact";
import FAQs from "./pages/FAQs";
import { Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const App = () => {
  const helmetContext = {};

  const hostname = window.location.hostname;
  let domain;
  if (hostname.endsWith(".ke")) {
    domain = "ke";
  } else if (hostname.endsWith(".ug")) {
    domain = "ug";
  } else {
    domain = "ug";
  }

  const [userData, setUserData] = useState([]);

  const getUserData = () => {
    const newData = [
      Cookies.get("userId"),
      Cookies.get("userType"),
      Cookies.get("userName"),
      Cookies.get("userEmail"),
      Cookies.get("userPassword"),
      Cookies.get("userOrg"),
      Cookies.get("userPermissions"),
      Cookies.get("userAnonymous"),
    ];
    setUserData(newData);
  };

  useEffect(() => {
    getUserData();

    // Check if the "userId" cookie is expired
    const userIdCookie = Cookies.get("userId");
    const isUserIdExpired =
      userIdCookie && new Date(userIdCookie.expires) < new Date();

    if (isUserIdExpired) {
      window.location.reload(); // Refresh the page if the "userId" cookie is expired
    }
  }, []);

  const refreshUserData = () => {
    getUserData();
  };

  const signOut = () => {
    Cookies.remove("userId");
    Cookies.remove("userType");
    Cookies.remove("userName");
    Cookies.remove("userEmail");
    Cookies.remove("userPassword");
    Cookies.remove("userOrg");
    Cookies.remove("userPermissions");
    window.location.href = "/";
  };

  return (
    <>
      <Navbar
        domain={domain}
        userData={userData}
        signOut={signOut}
      />
      <div className="row">
        {userData[0] && (
          <div
            className="position-fixed bleep-sidenav"
            style={{
              height: "100vh",
              width: "150px",
              backgroundColor: "#131313",
              // backgroundImage:
              //   "linear-gradient(180deg, #1e1e1e 0%, #000000ff 100%)",
              margin: "0px",
              padding: "0px",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                overflowY: "auto",
                height: `calc(100vh - 210px)`,
                paddingBottom: "40px",
              }}
            >
              <Link
                className="bleep-sidenav-btn"
                to="/"
              >
                <i className="fas fa-home"></i>
                <br />
                Home
              </Link>

              <Link
                className="bleep-sidenav-btn"
                to="/mybleeps"
              >
                <i className="fas fa-th"></i>
                <br />
                Bleeps
              </Link>

              <Link
                className="bleep-sidenav-btn"
                to="/myaccount"
              >
                <i className="fas fa-user-circle"></i>
                <br />
                Account
              </Link>

              {(userData[1] === "superadmin" || userData[1] === "admin") && (
                <Link
                  className="bleep-sidenav-btn"
                  to="/accounts"
                >
                  <i className="fas fa-users"></i>
                  <br />
                  Accounts
                </Link>
              )}

              {(userData[1] === "superadmin" || userData[1] === "admin") && (
                <Link
                  className="bleep-sidenav-btn"
                  to="/analytics"
                >
                  <i className="fas fa-chart-line"></i>
                  <br />
                  Analytics
                </Link>
              )}
            </div>
            <Link
              className="bleep-sidenav-btn-signout"
              onClick={signOut}
              style={{ position: "absolute", bottom: "100px", width: "150px" }}
            >
              <i className="fas fa-sign-out"></i>
              <br />
              Sign-out
            </Link>
          </div>
        )}

        <div className={userData[0] ? "bleep-responsive-content" : ""}>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  domain={domain}
                  userData={userData}
                  refreshUserData={refreshUserData}
                  signOut={signOut}
                />
              }
            />
            <Route
              path="/mybleeps"
              element={
                <MyBleeps
                  domain={domain}
                  userData={userData}
                />
              }
            />
            <Route
              path="/myaccount"
              element={
                <MyAccount
                  domain={domain}
                  userData={userData}
                  refreshUserData={refreshUserData}
                />
              }
            />
            <Route
              path="/post/:id"
              element={
                <Post
                  domain={domain}
                  refreshUserData={refreshUserData}
                  userData={userData}
                />
              }
            />
            <Route
              path="/about"
              element={
                <About
                  domain={domain}
                  userData={userData}
                />
              }
            />
            <Route
              path="/signin"
              element={
                <SignIn
                  domain={domain}
                  userData={userData}
                  handleSignIn={getUserData}
                />
              }
            />
            <Route
              path="/accounts"
              element={
                <Accounts
                  domain={domain}
                  userData={userData}
                />
              }
            />
            <Route
              path="/analytics"
              element={
                <Analytics
                  domain={domain}
                  userData={userData}
                />
              }
            />
            <Route
              path="/downloads"
              element={
                <Downloads
                  domain={domain}
                  userData={userData}
                />
              }
            />
            <Route
              path="/verify"
              element={
                <Verify
                  domain={domain}
                  userData={userData}
                />
              }
            />
            <Route
              path="/termsofservice"
              element={
                <TermsOfService
                  domain={domain}
                  userData={userData}
                />
              }
            />
            <Route
              path="/about"
              element={
                <About
                  domain={domain}
                  userData={userData}
                />
              }
            />
            <Route
              path="/contact"
              element={
                <Contact
                  domain={domain}
                  userData={userData}
                />
              }
            />
            <Route
              path="/faqs"
              element={
                <FAQs
                  domain={domain}
                  userData={userData}
                />
              }
            />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default App;
