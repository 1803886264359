import React, { useEffect, useState } from "react";
import CountryRibbon from "../components/CountryRibbon";
import Post from "../components/Post";
import ReportForm from "../components/ReportForm";
import EmailAuth from "../components/EmailAuth";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import lottie from "lottie-web";
// import Cookies from "js-cookie";
import lightninganimation from "../components/lottie/lightning.json";
import BleepSpinnerDark from "../components/lottie/BleepSpinnerDark.json";
import { Helmet } from "react-helmet-async";

const Home = ({ domain, userData, refreshUserData, signOut }) => {
  const [userDataState, setUserDataState] = useState(userData);
  const [showFloatingReportButton, setShowFloatingReportButton] =
    useState(false);

  useEffect(() => {
    upadteDownloadTrackerCount();
    const handleScroll = () => {
      // Calculate the scroll position
      const scrollY = window.scrollY || window.pageYOffset;

      // Toggle the visibility of the button based on the scroll position
      if (scrollY > 200) {
        // Adjust the value (100 in this case) to determine when to show the button
        setShowFloatingReportButton(true);
      } else {
        setShowFloatingReportButton(false);
      }
    };

    // Add scroll event listener when component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setUserDataState(userData);
  }, [userData]);

  const [
    userId,
    userType,
    userName,
    userEmail,
    userPassword,
    userOrg,
    userPermissions,
    userAnonymous,
  ] = userDataState;

  const [posts, setPosts] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const bleeploadinganimation = React.useRef(null);
  useEffect(() => {
    if (
      bleeploadinganimation.current &&
      !bleeploadinganimation.current.animationLoaded
    ) {
      bleeploadinganimation.current.animationLoaded = true; // Mark animation as loaded to prevent re-render
      lottie.loadAnimation({
        container: bleeploadinganimation.current,
        renderer: "canvas",
        loop: true,
        autoplay: true,
        animationData: BleepSpinnerDark,
      });
    }
  }, [isLoading]);

  const lightninganimationcontainer = React.useRef(null);
  useEffect(() => {
    if (
      lightninganimationcontainer.current &&
      !lightninganimationcontainer.current.animationLoaded
    ) {
      lightninganimationcontainer.current.animationLoaded = true; // Mark animation as loaded to prevent re-render
      lottie.loadAnimation({
        container: lightninganimationcontainer.current,
        renderer: "canvas",
        loop: true,
        autoplay: true,
        // speed: 0.2,
        animationData: lightninganimation,
      });
      lottie.setSpeed(0.6);
    }
  }, []);

  const fetchPosts = () => {
    let url = "/api/posts";
    if (userId) {
      url += `?user=${userId}`;
    }

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPosts(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        setIsLoading(false);
      });
  };

  // Fetch posts on component mount
  useEffect(() => {
    // setIsLoading(true);
    fetchPosts();
  }, [userId]);

  // Fetch posts after form submission
  const handleReportFormSubmit = () => {
    fetchPosts();
  };

  const [showReportFormModal, setShowReportFormModal] = useState(false);
  const handleCloseReportFormModal = () => {
    setShowReportFormModal(false);
    setReportFormInProgress(false);
  };
  const handleShowReportFormModal = () => {
    setShowReportFormModal(true);
    setReportFormInProgress(true);
  };

  const [showEmailAuthModal, setShowEmailAuthModal] = useState(false);
  const handleCloseEmailAuthModal = () => {
    setShowEmailAuthModal(false);
    if (reportFormInProgress) {
      setShowReportFormModal(true);
    }
  };
  const handleShowEmailAuthModal = () => setShowEmailAuthModal(true);

  const [reportFormInProgress, setReportFormInProgress] = useState(false);

  const handleSaveAndShowEmailAuthModal = () => {
    setReportFormInProgress(true);
    setShowReportFormModal(false);
    setShowEmailAuthModal(true);
  };

  const handleAuthComplete = () => {
    handleCloseEmailAuthModal();
    refreshUserData();
  };

  const trackEvent = (action) => {
    fetch("/api/trackers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: userId,
        action: action,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Event tracked:", data);
      })
      .catch((error) => {
        console.error("Error tracking event:", error);
      });
  };

  const [downloadTrackerCount, setDownloadTrackerCount] = useState(0);

  const upadteDownloadTrackerCount = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("/api/trackers/count/download,apk", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDownloadTrackerCount(result.count);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <Helmet>
        <title>BLEEP - Suspect a counterfeit? Report Now!</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      {/* <CountryRibbon domain={domain} /> */}
      <div
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "repeat",
          backgroundSize: "500px 500px",
          position: "relative",
        }}
      >
        <div
          style={{
            paddingTop: "20px",
            paddingBottom: "180px",
            background:
              "linear-gradient(90deg, rgba(255, 64, 0, 0.914) 0%, rgb(255, 153, 1) 100%)",
          }}
        >
          <div className="row d-flex align-items-center">
            <div
              className="col col-md-8 col-lg-9"
              style={{
                color: "white",
                paddingInline: "30px",
                paddingTop: "20px",
              }}
            >
              {userEmail != undefined && (
                <div
                  style={{
                    padding: "20px",
                    marginBottom: "30px",
                    borderRadius: "30px",
                    // borderTopLeftRadius: "20px",
                    // borderBottomLeftRadius: "20px",
                    // borderTopRightRadius: "60px",
                    // borderBottomRightRadius: "60px",
                    border: "2px solid #000000",
                    background: "rgb(0,0,0)",
                    background:
                      "linear-gradient(90deg, rgba(52, 24, 0, 0.935) 0%, rgba(0, 0, 0, 0) 100%)",
                  }}
                >
                  {userType != undefined && (
                    <b>
                      {userType} {userOrg != undefined ? " | " + userOrg : ""}
                    </b>
                  )}

                  {userAnonymous == "true" ? (
                    <h3
                      style={{
                        fontSize: "25px",
                        lineHeight: "20px",
                        textShadow: "#1c1c1c 1px 2px 0px",
                      }}
                    >
                      Welcome, Bleeper#{userId.slice(-6)}{" "}
                      <Link
                        to="/myaccount"
                        className="text-white"
                      >
                        <i className="fas fa-edit"></i>
                      </Link>
                    </h3>
                  ) : (
                    <h3
                      style={{
                        fontSize: "25px",
                        lineHeight: "20px",
                        textShadow: "#1c1c1c 1px 2px 0px",
                      }}
                    >
                      Welcome, {userName}
                    </h3>
                  )}
                  <a
                    className="text-white text-decoration-none"
                    href=""
                    onClick={() => {
                      signOut();
                    }}
                  >
                    Click HERE to sign out <i className="fas fa-sign-out"></i>
                  </a>
                </div>
              )}

              {userId == null && (
                <p
                  style={{
                    fontSize: "30px",
                    lineHeight: "20px",
                    textShadow: "#1c1c1c 1px 2px 0px",
                  }}
                >
                  Welcome Bleeper!
                </p>
              )}
              <b
                style={{
                  fontSize: "30px",
                  lineHeight: "20px",
                  textShadow: "#1c1c1c 1px 2px 0px",
                }}
              >
                Suspect a counterfeit?
              </b>
              <div
                style={{
                  paddingTop: "20px",
                }}
                className="align-items-center"
              >
                <div
                  style={{
                    marginBottom: "20px",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    paddingBlock: "10px",
                    marginRight: "20px",
                    width: "100%",
                  }}
                >
                  {(userId == null || userType == "user") && (
                    <button
                      type="button"
                      className="btn btn-light btn-lg shimmer bleep-btn"
                      onClick={handleShowReportFormModal}
                    >
                      <span style={{ marginRight: "10px" }}>
                        <i className="fa-solid fa-bolt-lightning"></i>
                      </span>
                      <b>REPORT NOW</b>
                    </button>
                  )}

                  {userId != null && (
                    <Link to="/mybleeps">
                      <button
                        type="button"
                        className="btn btn-light btn-lg bleep-btn"
                        style={{ marginLeft: "10px" }}
                      >
                        <span style={{ marginRight: "10px" }}>
                          <i className="fa-solid fa-th"></i>
                        </span>
                        <b>Bleeps</b>
                      </button>
                    </Link>
                  )}

                  {userId != null && (
                    <Link to="/myaccount">
                      <button
                        type="button"
                        className="btn btn-light btn-lg bleep-btn"
                        style={{ marginLeft: "10px" }}
                      >
                        <span style={{ marginRight: "10px" }}>
                          <i className="fa-solid fa-user-circle"></i>
                        </span>
                        <b>Account</b>
                      </button>
                    </Link>
                  )}

                  {(userType === "superadmin" || userType === "admin") && (
                    <Link to="/accounts">
                      <button
                        type="button"
                        className="btn btn-light btn-lg bleep-btn"
                        style={{ marginLeft: "10px" }}
                      >
                        <span style={{ marginRight: "10px" }}>
                          <i className="fa-solid fa-users"></i>
                        </span>
                        <b>Accounts</b>
                      </button>
                    </Link>
                  )}

                  {(userType === "superadmin" || userType === "admin") && (
                    <Link to="/analytics">
                      <button
                        type="button"
                        className="btn btn-light btn-lg bleep-btn"
                        style={{ marginLeft: "10px" }}
                      >
                        <span style={{ marginRight: "10px" }}>
                          <i className="fas fa-chart-line"></i>
                        </span>
                        <b>Analytics</b>
                      </button>
                    </Link>
                  )}
                </div>

                {/* <a
                  onClick={() => {
                    trackEvent("Downloaded v1.2.2 Beta APK");
                  }}
                  href="/downloads/bleep v1.2.2 Beta.apk"
                  download={true}
                >
                  <div
                    className="badge rounded-pill text-bg-dark"
                    style={{
                      marginBottom: "20px",
                      padding: "10px",
                    }}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <i className="fas fa-download"></i>
                    </span>
                    Download the Bleep App (Android)
                    {(userType === "superadmin" || userType === "admin") &&
                      " - " + downloadTrackerCount + " downloads"}
                  </div>
                </a> */}

                {/* Playstore button */}
                <a
                  onClick={() => {
                    trackEvent("Clicked Play Store Link");
                  }}
                  href="https://play.google.com/store/apps/details?id=com.acha.BLEEP"
                >
                  <img
                    src="/images/playstore_btn.svg"
                    style={{ height: "50px", marginRight: "5px" }}
                  />
                </a>
                {/* <img
                src="/images/appstore_btn.svg"
                style={{ height: "50px" }}
              /> */}

                <div
                  style={{
                    width: 400,
                    height: 400,
                    top: 0,
                    left: 0,
                    position: "absolute",
                    pointerEvents: "none",
                  }}
                >
                  <div
                    ref={lightninganimationcontainer}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            {/* Sliding image carousel */}
            <div
              className="col-3 col-md-4 col-lg-3 d-none d-md-block d-xl-block"
              style={{
                margin: "0px",
                paddingRight: "30px",
                paddingBottom: "20px",
              }}
              align="right"
            >
              <Splide
                options={{
                  perPage: 1,
                  rewind: true,
                  autoplay: true,
                  interval: 2000,
                  arrows: false,
                }}
              >
                <SplideSlide>
                  <div style={{ position: "relative" }}>
                    <img
                      src="/images/slide1.png"
                      style={{ width: "100%", borderRadius: "20px" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        textAlign: "center",
                        width: "100%",
                        padding: "10px",
                        paddingBottom: "20px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust background color and opacity as needed
                        color: "#fff", // Adjust text color as needed
                        borderRadius: "20px",
                        lineHeight: "20px",
                      }}
                      className="caption"
                    >
                      <b>REPORT</b>
                      <br />
                      <p>
                        Empowering consumers to report counterfeit products.
                      </p>
                    </div>
                  </div>
                </SplideSlide>

                <SplideSlide>
                  <div style={{ position: "relative" }}>
                    <img
                      src="/images/slide2.png"
                      style={{ width: "100%", borderRadius: "20px" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        textAlign: "center",
                        width: "100%",
                        padding: "10px",
                        paddingBottom: "20px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust background color and opacity as needed
                        color: "#fff", // Adjust text color as needed
                        borderRadius: "20px",
                        lineHeight: "20px",
                      }}
                      className="caption"
                    >
                      <b>VERIFY</b>
                      <br />
                      <p>Creating Awareness about Counterfeits</p>
                    </div>
                  </div>
                </SplideSlide>

                <SplideSlide>
                  <div style={{ position: "relative" }}>
                    <img
                      src="/images/slide3.png"
                      style={{ width: "100%", borderRadius: "20px" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        textAlign: "center",
                        width: "100%",
                        padding: "10px",
                        paddingBottom: "20px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust background color and opacity as needed
                        color: "#fff", // Adjust text color as needed
                        borderRadius: "20px",
                        lineHeight: "20px",
                      }}
                      className="caption"
                    >
                      <b>TAKE ACTION</b>
                      <br />
                      <p>
                        Increase responsiveness & accountability by brands and
                        enforcement agencies.
                      </p>
                    </div>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-0">
        {/* Categories */}
        {/* <div
          className="col col-lg-2 d-none d-lg-block sticky-top"
          style={{
            border: "2px solid rgb(67, 67, 67)",
            backgroundColor: "#303030",
            borderRadius: "20px",
            paddingInline: "10px",
            paddingBottom: "10px",
            overflowY: "scroll",
            height: "100vh",
            zIndex: 1,
          }}
        >
          <div
            className="text-shadow-bleep"
            style={{
              color: "rgb(255, 255, 255)",
              fontSize: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Categories
          </div>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-leaf"></i>
            </span>{" "}
            Agriculture
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-shirt"></i>
            </span>{" "}
            Apparel
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-car-side"></i>
            </span>{" "}
            Automobiles
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-wine-glass"></i>
            </span>{" "}
            Beverages
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-hammer"></i>
            </span>{" "}
            Construction
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-person-rays"></i>
            </span>{" "}
            Cosmetics
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-sack-dollar"></i>
            </span>{" "}
            Currencies
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-graduation-cap"></i>
            </span>{" "}
            Education
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-microchip"></i>
            </span>{" "}
            Electronics
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-utensils"></i>
            </span>{" "}
            Food
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-building-columns"></i>
            </span>{" "}
            Government
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-film"></i>
            </span>{" "}
            Media
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-building-user"></i>
            </span>{" "}
            Organizations
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-oil-well"></i>
            </span>{" "}
            Petroleum
          </button>
          <button className="btn btn-sector w-100">
            <span className="m-3">
              <i className="fa-solid fa-pills"></i>
            </span>{" "}
            Pharmaceuticals
          </button>
        </div> */}

        <div
          className="col"
          style={{
            marginTop: "-160px",
            padding: "20px",
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 1) 95%, rgba(255, 255, 255, 0)",
            zIndex: 1000,
          }}
        >
          {posts?.length > 0 && !isLoading ? (
            <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-1 row-cols-1">
              {posts.map((post) => (
                <Post
                  key={post._id}
                  post={post}
                  showEmailAuthModal={handleShowEmailAuthModal}
                />
              ))}
            </div>
          ) : (
            <div
              className="empty col-12"
              style={{
                color: "white",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                paddingTop: "50px",
              }}
            >
              <b>
                {isLoading ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <div
                      ref={bleeploadinganimation}
                      style={{
                        width: 200,
                        height: 200,
                        backgroundColor: "#000000ff",
                      }}
                    ></div>
                  </div>
                ) : (
                  "No posts yet :/"
                )}
              </b>
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 10,
          right: 20,
          zIndex: 1000,
        }}
      >
        {/* Report Button */}
        {showFloatingReportButton && (userId == null || userType == "user") && (
          <div
            style={{
              paddingBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={handleShowReportFormModal}
            >
              <div className="btn-floating-report">
                <i
                  className="fa-solid fa-bolt-lightning"
                  style={{
                    color: "black",
                    fontSize: 30,
                  }}
                ></i>
              </div>
              <div
                style={{
                  backgroundColor: "#292929",
                  paddingInline: 20,
                  paddingBlock: 5,
                  fontSize: 10,
                  whiteSpace: "nowrap",
                  color: "white",
                  borderRadius: 20,
                  marginTop: -10,
                  zIndex: 1000,
                }}
              >
                <span>Report</span>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            paddingBottom: "20px",
          }}
        >
          {/* Cypheme Button */}
          <Link
            to="/verify"
            className="text-decoration-none"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="btn-floating-verify">
                <img
                  src="/images/verify.svg"
                  style={{ height: "50px", filter: "invert(1)" }}
                />
              </div>
              <div
                style={{
                  backgroundColor: "#292929",
                  paddingInline: 20,
                  paddingBlock: 5,
                  fontSize: 10,
                  whiteSpace: "nowrap",
                  color: "white",
                  borderRadius: 20,
                  marginTop: -10,
                  zIndex: 1000,
                }}
              >
                <span>Verify product</span>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <ReportForm
        domain={domain}
        show={showReportFormModal}
        handleClose={handleCloseReportFormModal}
        handleFormSubmit={handleReportFormSubmit}
        handleSaveAndShowEmailAuthModal={handleSaveAndShowEmailAuthModal}
      />
      <EmailAuth
        domain={domain}
        show={showEmailAuthModal}
        handleClose={handleCloseEmailAuthModal}
        handleAuthComplete={handleAuthComplete}
      />
    </>
  );
};

export default Home;
